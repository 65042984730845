import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

enum Range {
  Before, Within, After
}

@Component({
  selector: 'app-counting',
  templateUrl: './counting.component.html',
  styleUrls: ['./counting.component.scss']
})
export class CountingComponent implements OnInit {

  private _sec: number = 1000;
  private _min: number = 60 * this._sec;
  private _hour: number = 60 * this._min;
  private _day = 24 * this._hour;
  private _week = 7 * this._day;

  private _start: Date = new Date(Date.UTC(2021,4,16,4,0,0));
  private _end: Date = new Date(Date.UTC(2021,4,17,4,0,0));

  Range: typeof Range = Range;
  state: Range;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  constructor() { }

  ngOnInit() {
    interval(1000).subscribe(() => {
      const now = Date.now();

      if (this._start.valueOf() - now > 0) {
        // not yet
        this.state = Range.Before;
        this.setCounter(this._start.valueOf() - now);
        return;
      }

      if (this._end.valueOf() - now < 0) {
        // passed
        this.state = Range.After;
        this.setCounter(now - this._end.valueOf());
        return;
      }

      // this is the day
      this.state = Range.Within;
    });
  }

  private setCounter(diff: number) {
    this.days = Math.floor(diff / this._day);
    this.hours = Math.floor(diff % this._day / this._hour);
    this.minutes = Math.floor(diff % this._hour / this._min);
    this.seconds = Math.floor(diff % this._min / this._sec);
  }
}
