import { EventEmitter } from 'events';
import { Point } from './point';
import { EquationManager } from './equation-manager';

export class FireFly extends EventEmitter {

    private _step: number;
    private _steps: number;
    private _position: Point;
    private _size: number;
    private _brightness: number;

    private _pathFinder: (step: number) => Point;
    private _dimmer: (step: number) => number;
    private _sizer: (step: number) => number;

    get position(): Point {
        return this._position;
    }

    get size(): number {
        return this._size;
    }

    get brightness(): number {
        return this._brightness;
    }

    constructor(
        origin: Point,
        destination: Point,
        maxBrightness: number,
        maxSize: number,
        steps: number
    ) {
        super();

        const equationManager = new EquationManager();
        const fractioner = this.getFractioner(steps, equationManager);

        this._dimmer = (step: number) => fractioner(step) * maxBrightness;
        this._sizer = (step: number) => fractioner(step) * maxSize;
        this._pathFinder = this.getPathFinder(origin, destination, steps, equationManager);
        this._steps = steps;
        this._step = 0;
    }

    private getPathFinder(origin: Point, destination: Point, steps: number, equationManager: EquationManager): (step: number) => Point {
        const bezier = equationManager.bezier(new Point(0.5, 1), new Point(0.5, 0));
        const linearX = equationManager.linear(new Point(0, origin.x), new Point(1, destination.x));
        const linearY = equationManager.linear(new Point(0, origin.y), new Point(1, destination.y));
        return (step: number) => {
            const percentage = bezier(step / steps);
            const x = linearX(percentage);
            const y = linearY(percentage);
            return new Point(x, y);
        }
    }

    private getFractioner(steps: number, equationManager: EquationManager): (step: number) => number {
        const bezier = equationManager.bezier(new Point(0.5, 1), new Point(0.5, 0));
        const progress: number[] = [];
        for (let i = 0; i <= steps; i++) {
            progress.push(bezier(i / steps));
        }

        const changes: number[] = [];
        progress.reduce((previous, current) => {
            changes.push(current - previous);
            return current;
        });

        const maxChange = Math.max(...changes);
        let fraction = changes.map(c => (maxChange - c) / maxChange);
        
        const maxFraction = Math.max(...fraction);
        fraction = fraction.map(f => f / maxFraction);
        fraction.unshift(0);

        return (step: number) => {
            return fraction[step];
        }
    }

    move(): void {
        if (this._step > this._steps) {
            this.emit("done");
            return;
        }

        this._position = this._pathFinder(this._step);
        this._brightness = this._dimmer(this._step);
        this._size = this._sizer(this._step);
        this._step++;
    }
}
