import { Point } from './point';
import * as BezierEasing from 'bezier-easing';

export class EquationManager {

    linear(point1: Point, point2: Point): (x: number) => number {
        const m = (point2.y - point1.y) / (point2.x - point1.x);
        const b = point1.y - m * point1.x;
        return (x: number) => m * x + b;
    }
    
    bezier(point1: Point, point2: Point): (x: number) => number {
        return BezierEasing(point1.x, point1.y, point2.x, point2.y);
    }
}
