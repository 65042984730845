import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { RecaptchaService } from './recaptcha.service';

declare class InvitationResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class InvitationCodeService {

  constructor(
    private _httpClient: HttpClient,
    private _recaptchaService: RecaptchaService
  ) { }

  private getInvitationToken(recaptchaToken: string, invitationCode: string): Observable<string> {
    return this._httpClient.post<InvitationResponse>('/api/invitation', { recaptchaToken, invitationCode }).pipe(
      catchError(err => {
        console.error(err);
        return of({ token: '' });
      }),
      map(resp => resp.token)
    );
  }

  exchange(invitationCode: string): Observable<string> {
    return this._recaptchaService.execute('invitation_code_exchange').pipe(
      switchMap(recaptchaToken => this.getInvitationToken(recaptchaToken, invitationCode))
    );
  }
}
