import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {


  private _navBackgroundOpacity: number = 0;
  private _navPaddingPx: number = this.getPadding(40, 20, 1);

  get navBackgroundColor(): string {
    return `rgba(30, 33, 29, ${this._navBackgroundOpacity})`;
  }

  get navPadding(): string {
    return `${this._navPaddingPx}px 0`;
  }

  get intersectThreshold(): number[] {
    return this.createThreshold(1, 0.8, 20);
  }

  private createThreshold(max: number, min: number, numberOfSteps: number) {
    const threshold: number[] = [];
    const step = (max - min) / numberOfSteps;
    for (let i = max; i > min; i -= step) {
      threshold.push(i);
    }

    return threshold;
  }

  private getPadding(max: number, min: number, threshold: number) {
    return (max - min) * Math.max(threshold, 0) + min;
  }

  scrolled(entry: IntersectionObserverEntry) {
    const moved = 1 - entry.intersectionRatio;
    const maxMoved = this.intersectThreshold[0] - this.intersectThreshold[this.intersectThreshold.length - 1];
    const threshold = Math.round((moved / maxMoved) * 100) / 100;
    this._navBackgroundOpacity = threshold;
    this._navPaddingPx = this.getPadding(40, 20, 1 - threshold);
  }
  
  ngOnInit(): void {
  }

}
