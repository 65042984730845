import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountingComponent } from './counting/counting.component';
import { HomeComponent } from './home/home.component';
import { TheWeddingComponent } from './the-wedding/the-wedding.component';
import { MapComponent } from './map/map.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VideoComponent } from './video/video.component';
import { VenueComponent } from './venue/venue.component';
import { LocationComponent } from './location/location.component';
import { RECAPTCHA_SITE_KEY } from './tokens';
import { HttpClientModule } from "@angular/common/http";
import { IntersectDirective } from './intersect.directive';
import { MainComponent } from './main/main.component';
import { FirefliesComponent } from './fireflies/fireflies.component';

@NgModule({
  declarations: [
    AppComponent,
    CountingComponent,
    HomeComponent,
    TheWeddingComponent,
    MapComponent,
    NavigationComponent,
    RsvpComponent,
    VideoComponent,
    VenueComponent,
    LocationComponent,
    IntersectDirective,
    MainComponent,
    FirefliesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: RECAPTCHA_SITE_KEY, useValue: '6LeyCd0UAAAAAOw2lCS2V9lNHZjM2n3AbMLPuG2T'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
