import { Component, OnInit } from '@angular/core';

declare const mapboxgl;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    mapboxgl.accessToken = 'pk.eyJ1IjoidHNlYWxleCIsImEiOiJjazZibGdibzQxMm5jM25xZTNrd2ZxMzQzIn0.QHtDYh2rE6C9OgvpTh8Gbw';
    const lngLat = new mapboxgl.LngLat(-79.592971, 43.832507);
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v10',
      center: lngLat,
      zoom: 13
    });
    map.scrollZoom.disable();
    const marker = new mapboxgl.Marker()
      .setLngLat(lngLat)
      .addTo(map);
  }

}
