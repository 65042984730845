<app-fireflies></app-fireflies>
<div class="block bg">
    <div class="content">
        <div class="hero">
            <h1>
                <span class="name">Eugenia</span>
                <span> & </span>
                <span class="name">Alex</span>
            </h1>
            <h2>are getting married!</h2>
        </div>
        <i class="material-icons more">keyboard_arrow_down</i>
    </div>
</div>