import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, Validators, AbstractControl, FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { RecaptchaService } from './recaptcha.service';
import { RsvpService } from './rsvp.service';
import { switchMap, debounceTime } from "rxjs/operators";
import { MatStepper } from '@angular/material/stepper';
import { InvitationCodeService } from './invitation-code.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RsvpComponent implements OnInit {

  @ViewChild(MatStepper)
  private _stepper: MatStepper;

  disabled: boolean;
  invitationForm: FormGroup;
  guestsForm: FormGroup;
  contactForm: FormGroup;
  rsvpForm: FormGroup;
  submissionForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  get guests(): FormArray {
    return this.guestsForm.get('guests') as FormArray;
  }

  get allGuestsControls(): AbstractControl[] {
    return this.guests.controls;
  }

  get primaryGuestControl(): AbstractControl {
    return this.allGuestsControls[0];
  }

  get isAccepted(): boolean {
    return this.rsvpForm.get('rsvp').value === 'yes';
  }

  constructor(
    private _recaptchaService: RecaptchaService,
    private _rsvpService: RsvpService,
    private _invitationCodeService: InvitationCodeService,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.invitationForm = this.createInvitation();
    this.guestsForm = this.createGuestsForm();
    this.contactForm = this.createContactForm();
    this.rsvpForm = this.createRsvpForm();
    this.submissionForm = this.createSubmissionForm();
    this.autoFillInvitationCode();
  }

  private autoFillInvitationCode() {
    const fragment = new URLSearchParams(this._route.snapshot.fragment);
    const invitationCode = fragment.get("rsvp");
    if (!invitationCode) return;

    this.invitationForm.patchValue({
      code: invitationCode
    });
  }

  private createInvitation(): FormGroup {
    const codeControl = new FormControl('', Validators.required);
    const tokenControl = new FormControl('', Validators.required);
    const formGroup = new FormGroup({
      code: codeControl,
      token: tokenControl
    });

    codeControl.valueChanges.pipe(
      debounceTime(500),
      switchMap(code => this._invitationCodeService.exchange(code))
    ).subscribe(token => {
      tokenControl.setValue(token);

      if (tokenControl.valid) {
        this._stepper.next();
      }
    });

    return formGroup;
  }

  private createGuest(): FormGroup {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      dietary: new FormGroup({
        isVegetarian: new FormControl(false),
        others: new FormControl('')
      })
    });
  }

  private createContactForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl(''),
      address: new FormControl('')
    });
  }

  private createRsvpForm(): FormGroup {
    return new FormGroup({
      rsvp: new FormControl('', Validators.required)
    });
  }

  private createGuestsForm(): FormGroup {
    return new FormGroup({
      guests: new FormArray([this.createGuest()])
    });
  }

  private createSubmissionForm(): FormGroup {
    return new FormGroup({
      comments: new FormControl(''),
      isSubmitted: new FormControl(false, Validators.requiredTrue)
    });
  }

  private onRecaptchaError() {
    this._snackBar.open("Please fill in the form manually, my friends.", "Close");
  }

  private onUseTokenError() {
    this._snackBar.open("Your session has been expired.  Please enter the form again.", "Close");
    this._stepper.reset();
  }

  private onUnhandledError() {
    this._snackBar.open("Hmm... Something wrong with the server.  Please try again later and contact one of us directly.", "Close");
    this._stepper.reset();
  }

  private disableControls(): void {
    this.disabled = true;
    this.invitationForm.disable();
    this.guestsForm.disable();
    this.contactForm.disable();
    this.rsvpForm.disable();
    this.submissionForm.disable();
  }

  private enableControls(): void {
    this.disabled = false;
    this.invitationForm.enable();
    this.guestsForm.enable();
    this.contactForm.enable();
    this.rsvpForm.enable();
    this.submissionForm.enable();
  }

  addGuest(): void {
    this.guests.push(this.createGuest());
  }

  deleteGuest(i: number): void {
    this.guests.removeAt(i);
  }

  submitted(): void {
    this.submissionForm.get('isSubmitted').setValue(true);
  }

  submit(): void {
    if (this.guestsForm.invalid) {
      throw Error('Invalid guests');
    }
    if (this.contactForm.invalid) {
      throw Error('Invalid contact');
    }
    if (this.rsvpForm.invalid) {
      throw Error('Invalid rsvp');
    }

    this.disableControls();
    this._recaptchaService.execute('rsvp').pipe(
      switchMap(recaptchaToken => this._rsvpService.submit(recaptchaToken, {
        invitationToken: this.invitationForm.value.token,
        ...this.guestsForm.value,
        contacts: this.contactForm.value,
        ...this.rsvpForm.value,
        ...this.submissionForm.value
      }))
    ).subscribe(result => {
      if (result.success) {
        this.submitted();
        this._stepper.next();
        return;
      }

      this.enableControls();
      switch (result.error.code) {
        case "INVALID_RECAPTCHA":
          this.onRecaptchaError();
        case "USE_TOKEN_ERROR":
          this.onUseTokenError();
        default:
          this.onUnhandledError();
      }
    }, error => {
      console.error(error);
      this.enableControls();
    });
  }
}
