import { Directive, Output, ElementRef, Input, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appIntersect]'
})
export class IntersectDirective implements OnInit {

  @Output('intersect') intersectEvent: EventEmitter<IntersectionObserverEntry> = new EventEmitter();
  @Input('threshold') threshold: number | number[] = 1;

  private _observer: IntersectionObserver;

  constructor(private elementRef: ElementRef) {
  }

  private onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    entries.forEach(entry => {
      this.intersectEvent.emit(entry);
    })
  }

  ngOnInit(): void {
    this._observer = new IntersectionObserver((entries, observer) => this.onIntersect(entries, observer), {
      threshold: this.threshold
    });

    this._observer.observe(this.elementRef.nativeElement);
  }
}
