import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Rsvp } from './rsvp';

@Injectable({
  providedIn: 'root'
})
export class RsvpService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  submit(recaptchaToken: string, rsvp: Rsvp): Observable<any> {
    return this._httpClient.post('/api/rsvp', { recaptchaToken, ...rsvp });
  }
}
