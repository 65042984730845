<div class="block">
    <div class="content">
        <div class="counting" [ngSwitch]="state">
            <div class="before" *ngSwitchCase="Range.Before">
                in
                <span class="part" *ngIf="days > 0"><span class="counter">{{ days }}</span> day{{ days > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="hours > 0"><span class="counter" *ngIf="hours > 0">{{ hours }}</span> hour{{ hours > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="minutes > 0"><span class="counter" *ngIf="minutes > 0">{{ minutes }}</span> minute{{ minutes > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="seconds > 0"><span class="counter" *ngIf="seconds > 0">{{ seconds }}</span> second{{ seconds > 1 ? 's': '' }}</span>
            </div>
            <div class="after" *ngSwitchCase="Range.After">
                <span class="part" *ngIf="days > 0"><span class="counter">{{ days }}</span> day{{ days > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="hours > 0"><span class="counter" *ngIf="hours > 0">{{ hours }}</span> hour{{ hours > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="minutes > 0"><span class="counter" *ngIf="minutes > 0">{{ minutes }}</span> minute{{ minutes > 1 ? 's': '' }} </span>
                <span class="part" *ngIf="seconds > 0"><span class="counter" *ngIf="seconds > 0">{{ seconds }}</span> second{{ seconds > 1 ? 's': '' }}</span>
                ago
            </div>
            <div class="within" *ngSwitchCase="Range.Within">
                <span>Today is the day!</span>
            </div>
        </div>
    </div>
</div>