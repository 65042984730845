<div class="block">
    <div class="content">
        <div class="container">
            <div class="details">
                <h2><a href="/assets/icalendars/eugeniaandalex.ics">May 16, 2021</a></h2>
                <div>
                    <div class="location">
                        <p class="venue">Kortright Centre</p>
                        <p><a href="https://goo.gl/maps/rLcRGAi6SEFqkrJN8" target="_blank">9550 Pine Valley Dr, Woodbridge, ON L4L 1A6</a></p>
                    </div>
                    <div class="schedule">
                        <p>Ceremony start at 5pm</p>
                        <p>Reception to follow at 6pm</p>
                    </div>
                    <!-- Button code -->
                    <div title="Add to Calendar" class="addeventatc">
                        Add to Calendar
                        <span class="start">05/16/2021 05:00 PM</span>
                        <span class="end">05/17/2021 12:00 AM</span>
                        <span class="timezone">America/Toronto</span>
                        <span class="title">Wedding of Eugenia and Alex</span>
                        <span class="description">Wedding of Eugenia and Alex</span>
                        <span class="location">9550 Pine Valley Dr, Woodbridge, ON L4L 1A6</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>