import { FireFly } from './fire-fly';
import { Point } from './point';

export class Garden {

    private _fireFlies: Set<FireFly>;

    constructor(private context: CanvasRenderingContext2D, count: number) {
        this.reset();
        this._fireFlies = new Set<FireFly>();
        for (let i = 0; i < count; i++) {
            this.addFireFly();
        }
        this.drawAll();
    }

    private clear(): void {
        this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    }

    private getRandomPoint(): Point {
        const x = Math.trunc(Math.random() * this.context.canvas.width);
        const y = Math.trunc(Math.random() * this.context.canvas.height);
        return new Point(x, y);
    }

    private addFireFly(): void {
        const origin = this.getRandomPoint();
        const destination = this.getRandomPoint();
        const maxBrightness = 1;
        const maxSize = Math.trunc(Math.random() * 10);
        const minSteps = 1000;
        const maxSteps = 2000;
        const steps = minSteps + Math.trunc(Math.random() * (maxSteps - minSteps));
        const fireFly: FireFly = new FireFly(origin, destination, maxBrightness, maxSize, steps);
        fireFly.on("done", () => {
            this._fireFlies.delete(fireFly);
            this.addFireFly();
        });
        fireFly.move();
        this._fireFlies.add(fireFly);
    }

    private draw(fireFly: FireFly): void {
        const x = fireFly.position.x;
        const y = fireFly.position.y;
        const radius = fireFly.size;
        this.context.beginPath();
        this.context.arc(x, y, radius, 0, Math.PI * 2);
        this.context.closePath();

        const gradient = this.context.createRadialGradient(x, y, 0, x, y, radius);
        const brightness = fireFly.brightness;
        gradient.addColorStop(0, `rgba(253, 250, 203, ${brightness}`);
        gradient.addColorStop(1, `rgba(248, 240, 217, 0)`);

        this.context.fillStyle = gradient;
        this.context.fill();
    }

    private drawAll(): void {
        this.clear();
        this._fireFlies.forEach(fireFly => {
            this.draw(fireFly);
            fireFly.move();
        });
        requestAnimationFrame(() => this.drawAll());
    }

    reset(): void {
        this.context.canvas.width = this.context.canvas.clientWidth;
        this.context.canvas.height = this.context.canvas.clientHeight;
    }
}
