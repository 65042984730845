import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RECAPTCHA_SITE_KEY } from '../tokens';

declare const grecaptcha: {
  ready: Function,
  execute: (siteKey: string, { action: string }) => Promise<string>;
};

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(
    @Inject(RECAPTCHA_SITE_KEY) private _siteKey: string
  ) { }

  execute(action: string): Observable<string> {
    return new Observable<string>(
      subscriber => {
        grecaptcha.ready(() => {
          grecaptcha.execute(this._siteKey, { action }).then(token => {
            subscriber.next(token);
            subscriber.complete();
          });
        });
      }
    );
  }
}
