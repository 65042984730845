<div class="block bg">
    <div class="content">
        <div class="container">
            <div class="header">
                <h2>The pleasure of your company is requested. </h2>
                <h3>Please respond by April 15th, 2021.</h3>
            </div>
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="invitationForm" editable="false">
                    <form [formGroup]="invitationForm">
                        <ng-template matStepLabel>Please enter the invitation code</ng-template>
                        <div class="fill-container">
                            <mat-form-field appearance="standard" class="fill">
                                <mat-label>Invitation code</mat-label>
                                <input matInput formControlName="code" required autocomplete="off" [errorStateMatcher]="matcher" />
                                <mat-error *ngIf="invitationForm.get('token').hasError('required') && !invitationForm.get('code').hasError('required')">
                                    Invalid invitation code.
                                </mat-error>
                                <mat-error *ngIf="invitationForm.get('code').hasError('required')">
                                    Invitation code is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="guestsForm">
                    <form [formGroup]="guestsForm">
                        <ng-template matStepLabel>Please fill out the names of your party members</ng-template>
                        <ng-container formArrayName="guests" *ngFor="let guestControl of allGuestsControls; index as i; first as isFirst">
                            <div [formGroupName]="i" class="fill-container">
                                <mat-form-field appearance="standard" class="fill">
                                    <mat-label>Full name</mat-label>
                                    <input matInput formControlName="name" placeholder="E.g) Alex Tse" required />
                                    <mat-error *ngIf="guestControl.get('name').hasError('required')">
                                        Name is required.
                                    </mat-error>
                                </mat-form-field>
                                <button mat-icon-button (click)="deleteGuest(i)" color="primary" *ngIf="!isFirst" [disabled]="disabled">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        <div class="actions">
                            <button mat-flat-button (click)="addGuest()" color="primary" [disabled]="disabled">Add a guest</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="contactForm">
                    <form [formGroup]="contactForm">
                        <ng-template matStepLabel>Please fill out your contact details</ng-template>
                        <div class="fill-container">
                            <mat-form-field appearance="standard" class="fill">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" placeholder="E.g) name@example.com" required email type="email" />
                                <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
                                    Please enter a valid email address.
                                </mat-error>
                                <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                    Email is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="fill-container">
                            <mat-form-field appearance="standard" class="fill">
                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="phone" placeholder="E.g) +1-416-123-4567" />
                            </mat-form-field>
                        </div>
                        <div class="fill-container">
                            <mat-form-field appearance="standard" class="fill">
                                <mat-label>Mailing Address</mat-label>
                                <input matInput formControlName="address" placeholder="Address" />
                            </mat-form-field>
                        </div>
                        <div class="actions">
                            <button mat-flat-button matStepperNext color="primary">Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="rsvpForm">
                    <form [formGroup]="rsvpForm">
                        <ng-template matStepLabel>Will you be able to attend?</ng-template>
                        <div>
                            <mat-radio-group formControlName="rsvp" color="primary" required>
                                <mat-error *ngIf="rsvpForm.get('rsvp').hasError('required')">
                                    Please select the one of the options.
                                </mat-error>
                                <mat-radio-button value="yes">Accept with pleasure</mat-radio-button>
                                <mat-radio-button value="no">Decline with regret</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="actions">
                            <button mat-flat-button matStepperNext color="primary">Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="guestsForm">
                    <form [formGroup]="guestsForm">
                        <ng-template matStepLabel>Please fill out dietary restiction (optional)</ng-template>
                        <ng-container formArrayName="guests" *ngFor="let guestControl of allGuestsControls; index as i">
                            <div [formGroupName]="i">
                                <label>{{ guestControl.value.name }}</label>
                                <div formGroupName="dietary" class="dietary-options fill-container">
                                    <mat-checkbox formControlName="isVegetarian" color="primary">Vegetarian</mat-checkbox>
                                    <mat-form-field appearance="standard" class="space-left fill">
                                        <mat-label>Other restrictions</mat-label>
                                        <input matInput formControlName="others" placeholder="E.g) No nuts, no dairy." />
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                        <div class="actions">
                            <button mat-flat-button matStepperNext color="primary">Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="submissionForm">
                    <form [formGroup]="submissionForm">
                        <ng-template matStepLabel>Submit</ng-template>
                        <div class="fill-container">
                            <mat-form-field appearance="standard" class="fill">
                                <mat-label>Anything else you would like to tell us?</mat-label>
                                <textarea matInput formControlName="comments"></textarea>
                            </mat-form-field>
                        </div>
                    </form>
                    <button mat-flat-button (click)="submit()" color="primary" [disabled]="disabled">Submit</button>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <div>
                        <ng-container *ngIf="isAccepted">
                            <p>Thank you for registering.</p>
                            <p>We look forward to seeing you on May 16, 2021.</p>
                        </ng-container>
                        <ng-container *ngIf="!isAccepted">
                            <p>Thank you for letting us know.</p>
                            <p>We can always meet at another time.</p>
                        </ng-container>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </div>
</div>