import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Garden } from './garden';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-fireflies',
  templateUrl: './fireflies.component.html',
  styleUrls: ['./fireflies.component.scss']
})
export class FirefliesComponent implements AfterViewInit, OnDestroy {

  @ViewChild("canvas")
  private _element: ElementRef;

  private _subscription: Subscription;

  constructor() { }

  ngAfterViewInit(): void {
    this.initGarden();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private initGarden(): void {
    const canvas = this._element.nativeElement as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    const garden: Garden = new Garden(context, 100);

    this._subscription = fromEvent(window, 'resize')
      .pipe(debounceTime(1000))
      .subscribe(() => {
        garden.reset();
      });
  }
}
